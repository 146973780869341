body {
  margin: 0;
  padding: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[data-hover]{
  cursor: pointer;
}

code {
  font-family: "Inter", sans-serif;
}

* {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

.razorpay-container #container.drishy {
  padding: 20px !important;
}